// export post item given data
import * as React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// Utilities
import kebabCase from "lodash/kebabCase"

// MUI components
import Box from "@mui/material/Box"
import Chip from "@mui/material/Chip"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"

const ClampTypography = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: "2",
  lineClamp: "2",
  WebkitBoxOrient: "vertical",
}

const PostItem = ({
  data: {
    frontmatter: { title, description, featuredImage, date },
    fields: { slug },
    timeToRead,
    category,
    excerpt,
  },
}) => {
  const image = getImage(featuredImage)

  return (
    <article style={{ width: "100%" }}>
      <Grid
        item
        xs={12}
        key={slug}
        itemScope
        itemType="http://schema.org/Article"
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "60px",
          "@media (max-width: 600px)": {
            gap: "1rem",
          },
        }}
      >
        <Box sx={{ padding: "0 !important" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              "@media (max-width: 600px)": { gap: 1.5 },
            }}
          >
            <Typography color="text.disabled" variant="body2">
              {date}
            </Typography>
            <Box
              component={Link}
              to={slug}
              sx={{
                textDecoration: "none",
                color: "unset",
                "& h2:hover": {
                  color: "primary.main",
                },
                "@media (max-width: 900px)": {
                  "& h2:hover": {
                    color: "unset",
                  },
                },
              }}
            >
              <Typography
                variant="h2"
                gutterBottom
                style={ClampTypography}
                sx={{
                  fontWeight: "700",
                  lineHeight: "28px",
                  fontSize: "22px !important",
                  letterSpacing: "0",
                  color: "text.primary",
                  transition: "color 0.2s ease-in-out",
                  "@media (max-width: 600px)": {
                    marginBottom: "0",
                    lineHeight: "20px",
                    fontSize: "16px !important",
                  },
                }}
              >
                {title || slug}
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                style={ClampTypography}
                sx={{
                  display: "initial",
                  fontFamily: "Charter",
                  marginBottom: "1rem",
                  color: "text.postBody",
                  "@media (max-width: 600px)": {
                    display: "none !important",
                  },
                }}
              >
                {description && `${description}`}
                {description && excerpt && `— `}
                {excerpt && `${excerpt}`}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
              {category && <Chip label={category} size="small" disabled />}
              <Typography sx={{ color: "text.secondary" }} variant="body2">
                {`${timeToRead} min read`}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Link to={slug}>
          <Box
            sx={{
              width: "100px",
              "@media (max-width: 600px)": {
                width: "80px",
              },
            }}
          >
            {image ? (
              <GatsbyImage
                image={image}
                alt={featuredImage.name}
                style={{ borderRadius: "4px" }}
              />
            ) : (
              ""
            )}
          </Box>
        </Link>
      </Grid>
    </article>
  )
}

export default PostItem
